import { createApp } from "vue";
import App from "./App.vue";

import router from "./router.js";
import store from "./store/index.js";
import VTooltip from 'v-tooltip'


// import {createMetaManager} from 'vue-3-meta';

const app = createApp(App);



app.use(router);

app.use(store);
app.use(VTooltip);
// app.use(createMetaManager());
// app.use(require('prerender-node'));

// await router.isReady();

app.mount("#app");
