<template>
	<the-header />

	<div class="content-box">
		<!-- da inserire slot cosi inserisco la diversa app in base alla richiesta-->

		<keep-alive>
			<div
				style="
					max-width: 20%;
					margin: 0 auto;
					padding: 10px;
					text-align: center;
				"
			>
				<n-switch v-model:value="lingua" size="small">
					<template #checked> English </template>
					<template #unchecked> Italiano </template>
				</n-switch>
			</div>
		</keep-alive>
		<main role="main">
			<router-view v-slot="{ Component, route }">
				<Transition name="page" mode="out-in">
					<div :key="route.name">
						<component :is="Component" :lingua="lingua"></component>
					</div>
				</Transition>
			</router-view>
		</main>
		<TheFooter />
	</div>
</template>

<script>
	import { defineComponent } from "vue";
	import TheHeader from "./components/TheHeader.vue";
	import TheFooter from "./components/TheFooter.vue";
	import { NSwitch } from "naive-ui";

	export default defineComponent({
		components: { TheHeader, NSwitch, TheFooter },
		data() {
			return {
				lingua: false,
			};
		},
		metaInfo: {
			meta: [
				{
					name: "description",
					content:
						"Sono Antonino, sono un ragazzo ventenne che da poco ha finito l'università, \
				non sono solo uno sviluppatore, anzi preferisco non dare una definizione a ciò che sono \
				per non essere costretto a darmi dei limiti; ho studiato Informatica e successivamente ho\
				studiato Ingegneria Robotica presso l'Università di Verona. Ho lavorato sodo in questi anni \
				infatti ho più volte svolto lavori paralleli alla mia attività di formazione didattica, \
				ottenendo due borse di studio presso l'Università di Verona negli anni 2021-22. \
				Ho lavorato molto anche alla mia tesi di laurea e sono felice che il mio lavoro possa\
				essere utilizzato successivamente per lo sviluppo scientifico in campo medico-robotica.\
				Attualmente il mio obiettivo è propormi come ingegnere libero professionista, per potermi dedicare principalmente\
				a ciò che mi piace, ovvero lo sviluppo di robot!  I'm Antonino, I'm a twenty-year-old boy who recently finished university, \
				I am not just a developer, in fact I prefer not to define what I am \
				so as not to be forced to give myself limits; I studied Computer Science and then I \
				studied Robotics Engineering at the University of Verona. I've worked hard over the years \
				in fact I have several times carried out parallel work to my didactic training activity, \
				obtaining two scholarships at the University of Verona in the years 2021-22. \
				I have also worked a lot on my degree thesis and I am happy that my work can \
				be used later for scientific development in the medical-robotics field. \
				Currently my goal is to propose myself as a freelance engineer, to be able to dedicate myself firstly to \
				what I like, which is the development of robots!",
				},
				{
					property: "og:title",
					content:
						"Antonino Parisi, Robotics Engineer & Computer Scientist",
				},
				{ property: "og:site_name", content: "Robotics Engineer" },
				{ property: "og:type", content: "website" },
				{ name: "robots", content: "index,follow" },
			],
		},
	});
</script>
<style lang="scss">
	.light-green {
		background-color: rgba(0, 128, 0, 0.12);
	}
	.green {
		background-color: rgba(0, 128, 0, 0.24);
	}

	html {
		background-color: #ffffff;

	}

	@media only screen and (max-width: 600px) {
		.content {
			position: relative;
			min-height: 70vh;
			min-width: 90vw;
		}

		#app {
			margin: auto;
			display: table;
			position: inherit;
		}

		.content-box {
			min-width: 65vw;
			min-height: 95vh;
			background-color: #ffffff74;
			/* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset; */
			box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
			position: relative;
			margin: auto;
			border-radius: 15px;
		}
		h1 {
			$extra-large: 2rem;
			$random-text: "foreign terrace";
			$stroke-width: 0; // $extra-large * 0.05;
			$green: #00ff00;
			$blue: rgb(0, 17, 255);
			$pink: #b34ddc;
			$white: #9e9e9e;
			$whiter: #ffffff;
			position: relative;
			display: inline-block;
			font-size: $extra-large;
			font-weight: 800;
			text-transform: uppercase;
			font-style: italic;
			color: transparent;
			-webkit-text-stroke-width: $stroke-width;
			-webkit-text-stroke-color: rgb(93, 54, 221);
			white-space: nowrap;
			$x: 12px;
			$y: 15px;
			$step: 1px;

			text-shadow: $x $y $white, $x + $step $y + $step $whiter,
				$x + $step * 2 $y + $step * 2 $blue,
				$x + $step * 3 $y + $step * 3 $blue,
				$x + $step * 4 $y + $step * 4 $blue,
				$x + $step * 5 $y + $step * 5 $blue,
				$x + $step * 6 $y + $step * 6 $blue,
				$x + $step * 7 $y + $step * 7 $blue;
		}
	}
	@media only screen and (min-width: 601px) and (max-width: 1000px) {
		
		.content {
			margin: 20px;
		}
		.content-box {
			min-width: 65vw;
			min-height: 95vh;
			max-width: 75vw;
			background-color: #ffffff74;
			margin: 0 auto;
			/* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset; */
			box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
			border-radius: 15px;
		}
		h1 {
			$extra-large: 5rem;
			$random-text: "foreign terrace";
			$stroke-width: 0; // $extra-large * 0.05;
			$green: #00ff00;
			$blue: rgb(0, 17, 255);
			$pink: #b34ddc;
			$white: #9e9e9e;
			$whiter: #ffffff;
			position: relative;
			display: inline-block;
			font-size: $extra-large;
			font-weight: 800;
			text-transform: uppercase;
			font-style: italic;
			color: transparent;
			-webkit-text-stroke-width: $stroke-width;
			-webkit-text-stroke-color: rgb(93, 54, 221);
			$x: 12px;
			$y: 15px;
			$step: 1px;

			text-shadow: $x $y $white, $x + $step $y + $step $whiter,
				$x + $step * 2 $y + $step * 2 $blue,
				$x + $step * 3 $y + $step * 3 $blue,
				$x + $step * 4 $y + $step * 4 $blue,
				$x + $step * 5 $y + $step * 5 $blue,
				$x + $step * 6 $y + $step * 6 $blue,
				$x + $step * 7 $y + $step * 7 $blue;
		}
	}

	@media only screen and (min-width: 1001px) {
		main {
			min-width: 60vw;
			max-width: 90vw;
			padding: 2rem;
		}
		.content {
			margin: 20px;
		}
		.content-box {
			min-width: 65vw;
			min-height: 95vh;
			max-width: 75vw;
			background-color: #ffffff74;
			margin: 0 auto;
			/* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset; */
			box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
			border-radius: 15px;
		}
		h1 {
			$extra-large: 4rem;
			$random-text: "foreign terrace";
			$stroke-width: 0; // $extra-large * 0.05;
			$green: #00ff00;
			$blue: rgb(0, 17, 255);
			$pink: #b34ddc;
			$white: #9e9e9e;
			$whiter: #ffffff;
			position: relative;
			display: inline-block;
			font-size: $extra-large;
			font-weight: 800;
			text-transform: uppercase;
			font-style: italic;
			color: transparent;
			-webkit-text-stroke-width: $stroke-width;
			-webkit-text-stroke-color: rgb(93, 54, 221);
			$x: 12px;
			$y: 15px;
			$step: 1px;

			text-shadow: $x $y $white, $x + $step $y + $step $whiter,
				$x + $step * 2 $y + $step * 2 $blue,
				$x + $step * 3 $y + $step * 3 $blue,
				$x + $step * 4 $y + $step * 4 $blue,
				$x + $step * 5 $y + $step * 5 $blue,
				$x + $step * 6 $y + $step * 6 $blue,
				$x + $step * 7 $y + $step * 7 $blue;
		}
	}

	/* transizone per cambio pagine */
	.page-enter-active,
	.page-leave-active {
		transition: opacity 1s, transform 1s;
	}
	.page-enter,
	.page-leave-to {
		opacity: 0;
		transform: translateX(-30%);
	}
</style>
