export default {
  timelineEN(state) {
    return state.timelineEN;
  },
  timeline(state) {
    return state.timeline;
  },
  intro(state) {
    return state.intro;
  },
  introEN(state) {
    return state.introEN;
  },
  projects(state) {
    return state.projects;
  },
  projectsEN(state) {
    return state.projectsEN;
  },
  web(state) {
    return state.web;
  },
  webEN(state) {
    return state.webEN;
  },
  ur5e(state) {
    return state.ur5e;
  },
  ur5eEN(state) {
    return state.ur5eEN;
  },
  articles(state) {
    return state.articles;
  },
  articlesEN(state) {
    return state.articlesEN;
  },
  ios(state) {
    return state.ios;
  },
  iosEN(state) {
    return state.iosEN;
  },

  JA(state) {
    return state.ja;
  },
  JAEN(state) {
    return state.jaEN;
  },
};
