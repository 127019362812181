<template>
	<footer>
		<span class="footer"> Copyright © Antonino Parisi 2023. P.Iva IT04949170239</span>
		<div class="right-footer"  v-tooltip="'OOOOO'">
			<div class="references">
				<LogoGithub @click="goPage('github')" class="logo" />
				<LogoLinkedin @click="goPage('linkedin')" class="logo"/>
			</div>
		</div>
	</footer>
</template>

<script>
	import { LogoGithub, LogoLinkedin } from "@vicons/ionicons5";
	export default {
		components: {
			LogoGithub,
			LogoLinkedin,
		},
		methods: {
			goPage(v) {
				if (v == "github")
					window.open("https://github.com/AntoninoParisi", "_blank");
				else
					window.open(
						"https://www.linkedin.com/in/antonino-parisi-988505213/",
						"_blank"
					);
			},
		},
	};
</script>

<style scoped>
	footer {
		min-width: 50vw;
		max-width: 100vw;
		max-height: 20vh;
		padding: 2rem;
		background: #eee;
		margin-top: 20rem;
	}
	.footer {
		justify-content: space-around;
		display: inline-block;
		position: inherit;
		/* margin-left:auto; */
		max-width: 50vw;

	}

	.right-footer {
		display: inline;
		float: right;
	}
	.references {
		display: flex;
		justify-content: center;
		align-content: right;
		min-width: 10vw;
		max-height: 70%;
		justify-content: space-around;

	}

	.logo {
		max-width: 35px;
	}
</style>
