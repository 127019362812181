import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      nome: "antonino",
      cognome: "parisi",
      timeline: [
        {
          type: "success",
          title: "J&A Agency",
          content:
            "Fondazione.",
          time: "2023-09-01 8:00",
          "line-type": "dashed",
        },
        {
          type: "success",
          title: "Laurea Magistrale",
          content:
            "Laureato presso l'Università degli Studi di Verona, Dipartimento di Informatica. Titolo tesi: Un confronto sperimentale di architetture di osservatori di disturbo in applicazioni di controllo di forza. Ha pubblicato due articoli relativi a questo lavoro.",
          time: "2023-03-15 13:00",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Carriera Freelance",
          content: "Punto di partenza per la mia carriera da freelance!",
          time: "2023-01-01",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Software Engineer",
          content: "Software Engineer presso Dacos S.R.L.",
          time: "2022-05-01 to 2022-12-31",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Borsa di Studio",
          content:
            "Borsa di studio su Web semantico e rappresentazione della conoscenza, in collaborazione con il Professor M.Cristani, utilizzando AI e tecniche di rappresentazione e modellazione dei dati.",
          time: "2021-04-21 to 2022-04-21",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Tutor Accademico",
          content:
            "Tutor Accademico del corso Controlli Automatici, Corso di Laurea triennale",
          time: "2020-09-01 to 2022-02-28",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Borsa di Studio",
          content:
            "Borsa di studio su DOB, sono entrato nell'Elastic Team in Altair Lab, collaborando con il Dr. A.Calanca, E.Dimo,R.Vicario e i colleghi di laurea Dr. N.Assolini e F.Grotto",
          time: "2020-09-01 to 2020-10-31",
          "line-type": "dashed",
        },
        {
          type: "success",
          title: "Laurea Triennale",
          content:
            "Laureato presso l'Università di Verona, Dipartimento di Informatica. Tesi : Disturbance Observers",
          time: "2020-07-15 13:00",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Nascita",
          content: "Dove tutto è iniziato",
          time: "1998-07-23",
          "line-type": "dashed",
        },
      ],
      timelineEN: [
        {
          type: "success",
          title: "J&A Agency",
          content:
            "Foundation.",
          time: "2023-09-01 8:00",
          "line-type": "dashed",
        },
        {
          type: "success",
          title: "Master Degree",
          content:
            "Graduated at University of Verona, Computer Science Department. Thesis title : An experimantal comparison of disturbance observer architectures in force control applications. Published two articles related to this work.",
          time: "2023-03-15 13:00",
          "line-type": "dotted",
        },
        {
          type: "info",
          title: "Freelance career",
          content: "Starting point of my freelance career!",
          time: "2023-01-01",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Software Engineer",
          content: "Software Engineer at Dacos S.R.L.",
          time: "2022-05-01 to 2022-12-31",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Scholarship",
          content:
            "Scholarship on Semantic Web and Knowlegde representation, working with Professor M.Cristani, using AI and data representation and modellation techniques.",
          time: "2021-04-21 to 2022-04-21",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Academic Course Tutor",
          content: "Academic Course Tutor of Control Theory in Bachelor Degree",
          time: "2020-09-01 to 2022-02-28",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Scholarship",
          content:
            "Scholarship on DOB, joined the Elastic Team in Altair Lab, working with Dr. A.Calanca, E.Dimo,R.Vicario and degree peers Dr. N.Assolini and F.Grotto ",
          time: "2020-09-01 to 2020-10-31",
          "line-type": "dashed",
        },
        {
          type: "success",
          title: "Bachelor Degree",
          content:
            "Graduated at University of Verona, Computer Science Department. Thesis argument : Disturbance Observers",
          time: "2020-07-15 13:00",
          "line-type": "dashed",
        },
        {
          type: "info",
          title: "Born",
          content: "Where everything started",
          time: "1998-07-23",
          "line-type": "dashed",
        },
      ],
      introEN:
        "I am Antonino, a twenty-year-old young man who has recently completed university studies. My education extends beyond the realm of development; in fact, I prefer not to confine myself to a single definition in order not to limit my potential. I earned a degree in Computer Science and later pursued my studies in Robotics Engineering at the University of Verona. Throughout this journey, I have consistently demonstrated dedication, even securing two scholarships from the University of Verona in the years 2021-22.\
        Over the years, I balanced my academic commitments with parallel work experiences, expanding my skill set. In particular, I have gained significant expertise in mobile app development and artificial intelligence. My thesis, focused on robotics and robust & high performance controllers, represents a substantial contribution to scientific research in this field.\
        Currently, my goal is to establish myself as a freelance engineer. This will allow me to pursue what I love most, which is the development of robots, and leverage the skills I have acquired during my educational journey.",

      intro:
        "Sono Antonino, un ragazzo ventenne che da poco ha completato gli studi universitari. La mia formazione non si limita all'ambito dello sviluppo, preferisco non circoscrivermi a una sola definizione per non limitare le mie potenzialità. Ho conseguito la laurea in Informatica e successivamente ho proseguito i miei studi in Ingegneria Robotica presso l'Università di Verona. Durante questo percorso, ho dimostrato impegno costante, ottenendo anche due borse di studio dall'Università di Verona negli anni 2021-22.\
        \n\
        Nel corso degli anni, ho compaginato il mio impegno accademico con esperienze lavorative parallele, ampliando le mie competenze. In particolare, ho acquisito notevoli capacità nello sviluppo di applicazioni mobile e nell'intelligenza artificiale. La mia tesi di laurea, dedicata alla robotica e ai controllori robusti ad alte prestazioni, rappresenta un contributo significativo alla ricerca scientifica in questo campo.\
        \n\
        Attualmente, il mio obiettivo è quello di intraprendere la carriera di ingegnere libero professionista. Questo mi consentirà di dedicarmi a ciò che amo di più, cioè lo sviluppo di robot, e di mettere a frutto le competenze acquisite nel corso del mio percorso formativo.",

      projects: {
        title: "Progettazione e sviluppo di Sistemi robotici",
        body: "Progettazione di soluzioni innovative attraverso lo studio del\
					caso, con proposte specifiche e adatte alla risoluzione del\
					problema. Lo studio comprende cinematica del robot, calcolo del\
					area di lavoro, implementazione tramite ROS del sistema finale.\
					Il passaggio da sviluppo a produzione può essere interamente\
					gestito tramite ROS, oppure è possibile utilizzare hardware di\
					prototipazione come arduino oppure mbed, per la creazione di\
					robot ad hoc.",
      },
      projectsEN: {
        title: "Robotics systems Design & Development",
        body: "Design of innovative solutions through the study of \
					case, with specific proposals suitable for resolving the \
					problem. The study includes robot kinematics, calculation of the \
					workspace, ROS implementation of the final system. \
					The transition from development to production can be entirely \
					managed through ROS, or you can use hardware from \
					prototyping such as arduino or mbed, for the creation of \
					ad hoc robot.",
      },
      web: {
        title: "Progettazione e sviluppo di soluzioni web",
        body: "Negli anni di università, e successivamente lavorando come\
					borsista e software engineer, ho sviluppato conoscenze\
					nell'ambito web, è possibile visionare le mie conoscenze nella\
					pagina iniziale. Sono in grado di gestire ampi progetti, creando\
					piani di sviluppo e gestendo l'avanzamento dei lavori.",
      },
      webEN: {
        title: "Innovative web solutions",
        body: "During his university years, and subsequently working as \
					fellow and software engineer, I have developed knowledge \
					in the web context, it is possible to view my knowledge in the \
					first page. I am able to manage large projects, creating \
					development plans and managing the progress of the work.",
      },
      ur5e: {
        title: "Progetto UR5e",
        body: "Ho lavorato ad un progetto dove si chiedeva la realizzazione di\
					una dimostrazione delle potenzialità di un pacchetto ROS,\
					chiamato MoveIt, dove sono stato realizzate tre differenti\
					tipologie di task, un classico pick & place, glue sealing, e\
					object inspection. Il robot utilizzava solo direttive del\
					pacchetto per raggiungere delle posizioni pre-definite\
					nell'ambiente di lavoro, lavorando sia nello spazio operazionale\
					(x,y,z) che nello spazio dei giunti.",
      },
      ur5eEN: {
        title: "UR5e project",
        body: "I worked on a project where the realization of \
					a demonstration of the potential of a ROS package, \
					called MoveIt, where three different \
					types of tasks, a classic pick & place, glue sealing, and \
					object inspection. The robot used only directives from the \
					package to reach pre-defined positions \
					in the workplace, working both in the operational space \
					(x, y, z) than in the joint space.",
      },
      articles: {
        title: "Articoli pubblicati",
        body: "Attualmente sono al lavoro su delle pubblicazioni che verranno\
					successivamente inviate a delle riviste internazionali affermate\
					sul campo della robotica, gli articoli comprendono il mio lavoro\
					di tesi magistrale su controllori robusti, in particolare sul\
					controllo in forza in ambienti con parametri non noti.",
      },
      articlesEN: {
        title: "Academic activities",
        body: "I am currently working on upcoming publications \
					subsequently sent to well-known international journals \
					in the field of robotics, the articles include my work \
					of master's thesis on robust controllers, in particular on \
					control in force in environments with unknown parameters.",
      },
      ios: {
        title: "Sviluppo App & Design",
        body: "Grazie alla collaborazione con J. Nikolic,\
        mi sono specializzato anche nello sviluppo di applicazioni mobile\
        nello sviluppo di siti web orientati al design e alla valorizzazione del prodotto.\
        Lo sviluppo di applicazioni mobile comprende la parte disponibile all'utente finale,\
        ma anche un gestionale personalizzabile e scalabile per un utilizzo a 360° del prodotto.",
      },
      iosEN: {
        title: "Sviluppo App & Design",
        body: "Thanks to the collaboration with J. Nikolic,\
        I also specialized in the development of mobile applications\
        in the development of websites oriented towards design and product enhancement.\
        Mobile application development includes the part available to the end user,\
        but also a customizable and scalable management system for 360° use of the product.",
      },
      ja:"Siamo una realtà nuova e dinamica, siamo giovani ed abbiamo abbastanza esperienza per poterlo dimostrare. Jovan Nikolic è fondatore e principle sviluppatore di Moodbe, applicazione nata da una startup innovativa nel 2020, Antonino Parisi invece è laureato in ingegneria informatica e ha vinto due borse di ricerca e sviluppo. Entrambi si impegnano da sempre a creare e investire nelle loro capacità.",
      jaEN:"We are a new and dynamic reality, we are young and have enough experience to prove it. Jovan Nikolic is the founder and principal developer of Moodbe, an application born from an innovative startup in 2020, while Antonino Parisi holds a degree in computer engineering and has won two research and development grants. Both have always been committed to creating and investing in their skills."
    };
  },
  mutations,
  actions,
  getters,
};
