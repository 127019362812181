<template>
	<div class="content">
		<NGrid
			n-grid
			x-gap="50"
			cols="3 xs:1 s:1 m:3 l:3 xl:3 2xl:3"
			responsive="screen"
		>
			<n-gi v-for="item in navLinks" :key="item.path" class="transp-back">
				<router-link
					:to="item.path"
					:class="
						this.$route.name != item.router_name
							? 'router-links'
							: 'router-link-active'
					"
					>{{ item.name }}</router-link
				>
			</n-gi>
		</NGrid>
	</div>
	<div class="personalIntro">
		<div class="centering-text">
			<h2 class="NOME">Antonino Parisi</h2>
			<h5 class="PROFESSIONE">Robotics Engineer</h5>
		</div>
		<p class="PROFESSIONE MOTTO">Ad Maiora Semper.</p>
	</div>
</template>

<script>
	import { NGi, NGrid } from "naive-ui";
	export default {
		name: "TheHeader",
		data() {
			return {
				navLinks: [
					{ path: "/home", name: "WHO", router_name: "home" },
					{ path: "/projects", name: "WHAT" },
					{ path: "/contattami", name: "WHERE" },
				],
			};
		},
		components: {
			NGi,
			NGrid,
		},
	};
</script>

<style lang="scss" scoped>
	.router {
		font-size: 5rem;
		&-links {
			text-decoration: none;
			font-weight: 700;
			font-size: 5rem;
			margin: 0 auto;
			text-align: center;
			box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
			background: white;
			color: black;
			mix-blend-mode: screen;
			padding-right: 0.85rem;
			padding-left: 0.85rem;
		}
		&-link-active {
			color: white;
			text-decoration: none;
			font-weight: 700;
			font-size: 5rem;
			margin: 0 auto;
			text-align: center;
			background: rgb(239, 22, 221);
			color: black;
			mix-blend-mode: screen;
			box-shadow: #730e9e89 0px 22px 70px 4px;
			padding-right: 1.85rem;
			padding-left: 1.85rem;
			-webkit-animation: shadow-pop-tr 0.3s
				cubic-bezier(0.47, 0, 0.745, 0.715) both;
			animation: shadow-pop-tr 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
				both;
		}
	}
	.transp-back {
		background-color: transparent;
	}

	@media only screen and (min-width: 1024px) {
		.content {
			background: url("~@/assets/imgs/back_navbar.png") no-repeat center
				center;
			background-size: cover;
			text-align: center;
			min-height: 15vh;
			display: inline-block;
			width: 90vw;
			padding: 5rem;
			position: relative;
			top: -20px;
			margin: auto;
			margin-top: 25px;
			z-index: 0;
			box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
		}
		.centering-text {
			// box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
			// 	rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
			// 	rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
			position: relative;
			line-height: 10vh;
			text-align: right;
			top: 5%;
			min-width: 50vw;
			padding: 5rem;
			padding-right: 35vw;

			// box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
		}
		.personalIntro {
			// background: url("~@/assets/imgs/test.gif") center center/contain
			// 	no-repeat padding-box;
			background: transparent;
			min-width: 90vw;
			min-height: 34vh;
			background-size: 100% 100%;
			text-align: right;
			position: relative;
			clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
			padding: 1rem;
			// border: 1px solid #000;
		}
		.NOME {
			color: white;
			text-decoration: none;
			font-weight: 700;
			font-size: 3rem;
			margin: auto;
			text-align: right;
			background: #32127a;
			padding-right: 10px;
			margin-bottom: 5px;
			mix-blend-mode: screen;
			// color: rgb(255, 255, 255);
			// font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
			// font-size: 125px;
			// font-weight: 100;
			// letter-spacing: -5px;
			// line-height: 1;
			// text-align: center;
			z-index: 2;
		}
		.COGNOME {
			// color: white;
			// text-decoration: none;
			// font-weight: 700;
			// font-size: 5rem;
			// margin: auto;
			// text-align: center;
			// background: rgba(255, 255, 255);
			// color: black;
			// mix-blend-mode: screen;
			// box-shadow: #730e9e89 0px 22px 70px 4px;
			color: rgb(255, 255, 255);
			font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
			font-size: 125px;
			font-weight: 100;
			letter-spacing: -1px;
			line-height: 1;
			text-align: right;
		}
		.PROFESSIONE {
			color: rgb(0, 0, 0);
			font-family: "Open Sans", serif;
			font-size: 30px;
			font-weight: 200;
			line-height: 32px;
			margin: 0 0 72px;
			text-align: right;
			float: right;
		}
	}
	@media only screen and (min-width: 601px) and (max-width: 1023px) {
		.content {
			background: url("~@/assets/imgs/back_navbar.png") no-repeat center
				center;
			text-align: center;
			min-height: 15vh;
			display: inline-block;
			min-width: 70vw;
			padding: 5rem;
			position: relative;
			top: -20px;
			margin: auto;
			margin-top: 25px;
			z-index: 0;
			box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
		}
		.centering-text {
			// box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
			// 	rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
			// 	rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
			position: relative;
			line-height: 10vh;
			text-align: right;
			top: 5%;
			width: 50%;
			padding: 5rem;
			padding-right: 35vw;

			// box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
		}
		.personalIntro {
			// background: url("~@/assets/imgs/test.gif") center center/contain
			// 	no-repeat padding-box;
			background: transparent;
			min-width: 90vw;
			min-height: 34vh;
			background-size: 100% 100%;
			text-align: right;
			position: relative;
			clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
			padding: 1rem;

			// border: 1px solid #000;
		}
		.NOME {
			color: white;
			text-decoration: none;
			font-weight: 700;
			font-size: 2.5rem;
			margin: auto;
			text-align: right;
			background: #32127a;
			padding-right: 10px;
			margin-bottom: 5px;
			mix-blend-mode: screen;
			// color: rgb(255, 255, 255);
			// font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
			// font-size: 125px;
			// font-weight: 100;
			// letter-spacing: -5px;
			// line-height: 1;
			// text-align: center;
			z-index: 2;
		}
		.COGNOME {
			// color: white;
			// text-decoration: none;
			// font-weight: 700;
			// font-size: 5rem;
			// margin: auto;
			// text-align: center;
			// background: rgba(255, 255, 255);
			// color: black;
			// mix-blend-mode: screen;
			// box-shadow: #730e9e89 0px 22px 70px 4px;
			color: rgb(255, 255, 255);
			font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
			font-size: 125px;
			font-weight: 100;
			letter-spacing: -1px;
			line-height: 1;
			text-align: right;
		}
		.PROFESSIONE {
			color: rgb(0, 0, 0);
			font-family: "Open Sans", serif;
			font-size: 20px;
			font-weight: 200;
			line-height: 32px;
			margin: 0 0 72px;
			text-align: right;
			float: right;
		}
	}
	@media only screen and (max-width: 600px) {
		.content {
			background: url("~@/assets/imgs/back_navbar.png") no-repeat center
				center;
			text-align: left;
			min-height: 15vh;
			display: inline-block;
			min-width: 70vw;
			padding: 5rem;
			position: relative;
			top: -20px;
			margin: auto;
			margin-top: 25px;
			z-index: 0;
			box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
		}
		.centering-text {
			// box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
			// 	rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
			// 	rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
			position: relative;
			text-align: left;
			top: 5%;
			width: 75%;

			// box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
		}
		.personalIntro {
			// background: url("~@/assets/imgs/test.gif") center center/contain
			// 	no-repeat padding-box;
			background: transparent;
			min-width: 105vw;
			min-height: 10vh;
			background-size: 100% 100%;
			text-align: right;
			position: relative;
			clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
			padding: 1rem;

			// border: 1px solid #000;
		}
		.NOME {
			color: white;
			text-decoration: none;
			font-weight: 700;
			font-size: 1.4rem;
			margin: auto;
			text-align: right;
			background: #32127a;
			padding-right: 10px;

			mix-blend-mode: screen;
			// color: rgb(255, 255, 255);
			// font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
			// font-size: 125px;
			// font-weight: 100;
			// letter-spacing: -5px;
			// line-height: 1;
			// text-align: center;
			z-index: 2;
		}
		.COGNOME {
			// color: white;
			// text-decoration: none;
			// font-weight: 700;
			// font-size: 5rem;
			// margin: auto;
			// text-align: center;
			// background: rgba(255, 255, 255);
			// color: black;
			// mix-blend-mode: screen;
			// box-shadow: #730e9e89 0px 22px 70px 4px;
			color: rgb(255, 255, 255);
			font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
			font-size: 125px;
			font-weight: 100;
			letter-spacing: -1px;
			line-height: 1;
			text-align: right;
		}
		.PROFESSIONE {
			color: rgb(0, 0, 0);
			font-family: "Open Sans", serif;
			font-size: 0.8rem;
			font-weight: 200;
			line-height: 32px;
			margin: 0 0 72px;
			text-align: right;
			float: right;
		}
		.left-subtitle {
			float: right;
		}
		.MOTTO {
			margin: 10px;
		}
	}

	/* ----------------------------------------------
 * Generated by Animista on 2023-9-12 10:46:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

	/**
 * ----------------------------------------
 * animation shadow-pop-tr
 * ----------------------------------------
 */
	@-webkit-keyframes shadow-pop-tr {
		0% {
			-webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e,
				0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
			box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e,
				0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
			-webkit-transform: translateX(0) translateY(0);
			transform: translateX(0) translateY(0);
		}
		100% {
			-webkit-box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e,
				3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e,
				6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
			box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e,
				4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e,
				7px -7px #3e3e3e, 8px -8px #3e3e3e;
			-webkit-transform: translateX(-8px) translateY(8px);
			transform: translateX(-8px) translateY(8px);
		}
	}
	@keyframes shadow-pop-tr {
		0% {
			-webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e,
				0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
			box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e,
				0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
			-webkit-transform: translateX(0) translateY(0);
			transform: translateX(0) translateY(0);
		}
		100% {
			-webkit-box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e,
				3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e,
				6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
			box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e,
				4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e,
				7px -7px #3e3e3e, 8px -8px #3e3e3e;
			-webkit-transform: translateX(-8px) translateY(8px);
			transform: translateX(-8px) translateY(8px);
		}
	}
</style>
