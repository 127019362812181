/* eslint-disable */

import {
  createRouter,
  createWebHistory,
  isNavigationFailure,
} from "vue-router";

//! PAGINE -------------------------------------------------------
const TheHomePage = () => import("./pages/TheHomePage.vue");
const TheProgetti = () => import("./pages/TheProgetti.vue");
const TheDove = () => import("./pages/TheDove.vue");

const The404 = () => import("./pages/The404.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "home",
      path: "/home",
      component: TheHomePage,
      alias: ["/"],
      meta: {
        requiresAuth: false,
      },
    },
    {
      name: "progetti",
      path: "/projects",
      component: TheProgetti,
      meta: {
        requiresAuth: false,
      },
    },
    {
      name: "contattami",
      path: "/contattami",
      component: TheDove,
      meta: {
        requiresAuth: false,
      },
    },

    { name: "error404", path: "/:pathMatch(.*)", component: The404 },
  ],
});

router.beforeEach(async (to, from, next) => {
  console.log(from, to);
  const guard = true; //await store.dispatch("auth/isAuth"); // credential chek every time is reloaded the page
  if (to.meta.requiresAuth && !guard) {
    next("/login");
  } else {
    next();
  }
});

router.afterEach((to, from, failure) => {
  if (isNavigationFailure(failure)) {
    console.log("failed navigation", failure);
  }
});

export default router;
